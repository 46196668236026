.App {
  text-align: center;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

html,
body {
  min-height: 100%;
}

body {
  background: url(/img/bg.png);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/fonts/ProximaNova.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("/fonts/ProximaNovaSemiBold.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("/fonts/ProximaNovaBold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

.league-description > a {
  color: #fff !important;
}
